import { AWSError } from 'aws-sdk'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAuthContext } from '../../hooks/useAuthContext'

export function SignIn() {
    const { signIn } = useAuthContext()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            try {
                signIn(email, password)
            } catch (error) {
                toast.error((error as AWSError).message)
            }
        },
        [email, password, signIn]
    )

    const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }, [])

    const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }, [])

    return (
        <div className="bg-gray-50 pt-60 h-screen">
            <form className="max-w-md w-full mx-auto shadow p-6 bg-white" onSubmit={handleSubmit}>
                <h1 className="text-3xl font-semibold text-center">
                    <span className="text-gray-800">Struktur Sarr</span>
                </h1>
                <div className="mt-4">
                    <label className="text-gray-600">E-Mail</label>
                    <input type="email" className="w-full p-2 shadow bg-gray-100" value={email} onChange={handleEmailChange} />
                </div>
                <div className="mt-4">
                    <label className="text-gray-600">Passwort</label>
                    <input type="password" className="w-full p-2 shadow bg-gray-100" value={password} onChange={handlePasswordChange} />
                </div>
                <div className="mt-6">
                    <button type="submit" className="w-full p-2 bg-red-500 shadow text-white font-semibold transition hover:opacity-75">
                        Anmelden
                    </button>
                </div>
            </form>
            <div className="text-center mt-4">
                <Link to="/sign-up" className="text-gray-600 py-1">
                    Noch keinen Account erstellt?
                </Link>
            </div>
        </div>
    )
}
