import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useParams } from 'react-router-dom'
import { content } from '../../content'
import { Navbar, CourseContent } from '..'

export function CourseDetail() {
    const slug = useParams()

    const course = content.find((value) => {
        return value.id === slug.kursId
    })

    if (!course) {
        return <h1>Course not found</h1>
    }

    return (
        <>
            <Navbar />
            <div className="max-w-screen-xl mx-auto ">
                <LazyLoadImage
                    effect="blur"
                    placeholderSrc={'/assets/VERKAUFSFÖRDERUNG.png'}
                    src={course.image}
                    width="100%"
                    height="500px"
                    className="mx-auto w-full object-center object-cover rounded mt-8"
                    style={{ height: '500px' }}
                />
                <div className="max-w-screen-lg mx-auto px-8 mt-16">
                    <div className="mx-auto mb-20">
                        <h1 className="text-2xl xl:text-6xl font-bold">{course.title}</h1>
                        <h2 className="text-lg text-gray-700">{course.author}</h2>
                        <h1 className="text-3xl mt-10 font-medium mb-4">Beschreibung</h1>
                        <p className="text-lg text-gray-600">{course.description}</p>

                        <CourseContent course={course} />
                    </div>
                </div>
            </div>
        </>
    )
}
