import React from 'react'
import ReactPlayer from 'react-player'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Navbar, CourseContent } from '..'
import { content } from '../../content'

export function CourseSession() {
    const slug = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const course = content.find((value) => {
        return value.id === slug.kursId
    })

    if (!course) {
        return <h1>Course not found</h1>
    }

    let number = 0
    const item = course.content.find((value, index) => {
        number = index
        return value.id === slug.classId
    })

    if (!item) {
        return <h1>Class not found</h1>
    }

    return (
        <>
            <Navbar />
            <div className="flex flex-col md:flex-row min-h-screen">
                <div className="w-full md:w-10/12 bg-gray-100 pb-16">
                    <div className="aspect-video">
                        <ReactPlayer playing controls url={item.link} width="100%" height="100%" />
                    </div>
                    <div className=" p-2 w-11/12 md:w-10/12 mx-auto">
                        <h1 className=" text-4xl md:text-5xl font-bold my-8">
                            {number + 1}. {item.title}
                        </h1>
                        <h2 className="text-3xl mb-4">Beschreibung</h2>
                        <p className="text-gray-600 text-lg whitespace-pre-wrap">{item.description}</p>
                    </div>
                </div>
                <div className="w-full md:w-4/12 overflow-scroll h-screen p-4">
                    <h1 className="text-3xl mt-6 font-semibold px-4">{course.title}</h1>
                    <CourseContent course={course} />
                </div>
            </div>
        </>
    )
}
