import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { SignIn, SignUp, ListOfCourses, CourseSession, CourseDetail } from './components'
import { AuthContextProvider } from './contexts'
import { AuthIsSignedIn, AuthIsSignedOut } from './guards'

function App() {
    return (
        <BrowserRouter>
            <AuthContextProvider>
                <ToastContainer />

                <Suspense fallback={<h1>Loading...</h1>}>
                    <Routes>
                        <Route
                            path="/sign-in"
                            element={
                                <AuthIsSignedOut>
                                    <SignIn />
                                </AuthIsSignedOut>
                            }
                        />
                        <Route
                            path="/sign-up"
                            element={
                                <AuthIsSignedOut>
                                    <SignUp />
                                </AuthIsSignedOut>
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <AuthIsSignedIn>
                                    <ListOfCourses />
                                </AuthIsSignedIn>
                            }
                        />
                        <Route
                            path="/kurse/:kursId"
                            element={
                                <AuthIsSignedIn>
                                    <CourseDetail />
                                </AuthIsSignedIn>
                            }
                        />
                        <Route
                            path="/kurse/:kursId/:classId"
                            element={
                                <AuthIsSignedIn>
                                    <CourseSession />
                                </AuthIsSignedIn>
                            }
                        />
                    </Routes>
                </Suspense>
            </AuthContextProvider>{' '}
        </BrowserRouter>
    )
}

export default App
