import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js'
import React, { useState, useEffect, createContext, useCallback } from 'react'
import { AWSError, CognitoIdentityCredentials, config } from 'aws-sdk'
import cdkOutputs from '../cdk-outputs.json'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

config.region = 'eu-central-1'

const userPool = new CognitoUserPool({
    UserPoolId: cdkOutputs.StrukturSarrStack.userPoolId,
    ClientId: cdkOutputs.StrukturSarrStack.userPoolClientId,
})

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut,
}

export interface User {
    id: string
    givenName: string
    familyName: string
    email: string
    level: string
}

export interface IAuth {
    authStatus: AuthStatus
    session: CognitoUserSession | null
    user: User | null
    signUp: (givenName: string, familyName: string, email: string, password: string, level: string) => void
    signIn: (email: string, password: string) => void
    signOut: () => void
}

const defaultState: IAuth = {
    authStatus: AuthStatus.Loading,
    session: null,
    user: null,
    signUp: () => {
        throw new Error('signUp not implemented')
    },
    signIn: () => {
        throw new Error('signIn not implemented')
    },
    signOut: () => {
        throw new Error('signOut not implemented')
    },
}

export const AuthContext = createContext<IAuth>(defaultState)

interface AuthContextProviderProps {
    children?: React.ReactNode
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.SignedOut)
    const [session, setSession] = useState<CognitoUserSession | null>(null)
    const [user, setUser] = useState<User | null>(null)

    const navigate = useNavigate()

    const getSession = useCallback(() => {
        const cognitoUser = userPool.getCurrentUser()

        if (!cognitoUser) {
            setAuthStatus(AuthStatus.SignedOut)
            return
        }

        cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
            if (err || !session) {
                toast.error((err as Error).message)
                setAuthStatus(AuthStatus.SignedOut)
                setSession(null)
                setUser(null)
                return
            }

            cognitoUser.getUserAttributes((err, attributes) => {
                if (err) {
                    toast.error(err.message)
                    setUser(null)
                } else {
                    const user: User = {
                        id: attributes?.find((attr) => attr.getName() === 'sub')?.getValue() || '',
                        givenName: attributes?.find((attr) => attr.getName() === 'given_name')?.getValue() || '',
                        familyName: attributes?.find((attr) => attr.getName() === 'family_name')?.getValue() || '',
                        email: attributes?.find((attr) => attr.getName() === 'email')?.getValue() || '',
                        level: attributes?.find((attr) => attr.getName() === 'level')?.getValue() || '',
                    }
                    setUser(user)
                }
            })

            config.credentials = new CognitoIdentityCredentials({
                IdentityPoolId: userPool.getUserPoolId(),
                Logins: {
                    [`cognito-idp.${config.region}.amazonaws.com/${userPool.getUserPoolId()}`]: session.getAccessToken().getJwtToken(),
                },
            })

            setSession(session)

            if (session.isValid()) {
                setAuthStatus(AuthStatus.SignedIn)
            }
        })
    }, [])

    const signUp = useCallback(
        (givenName: string, familyName: string, email: string, password: string, level: string) => {
            const attributes = []

            attributes.push(new CognitoUserAttribute({ Name: 'given_name', Value: givenName }))
            attributes.push(new CognitoUserAttribute({ Name: 'family_name', Value: familyName }))
            attributes.push(new CognitoUserAttribute({ Name: 'email', Value: email }))
            attributes.push(new CognitoUserAttribute({ Name: 'custom:level', Value: level }))

            userPool.signUp(email.toLowerCase(), password, attributes, [], (err) => {
                if (err) {
                    toast.error((err as AWSError).message.replace('PreSignUp failed with error ', ''))
                    return
                }
                toast.success('Registrieung erfolgreich')
                navigate('/sign-in')
            })
        },
        [navigate]
    )

    const signIn = useCallback((email: string, password: string) => {
        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        })
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        })
        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (result) => {
                config.credentials = new CognitoIdentityCredentials({
                    IdentityPoolId: userPool.getUserPoolId(),
                    Logins: {
                        [`cognito-idp.${config.region}.amazonaws.com/${userPool.getUserPoolId()}`]: result.getAccessToken().getJwtToken(),
                    },
                })

                setAuthStatus(AuthStatus.SignedIn)
            },
            onFailure: (err) => {
                toast.error((err as AWSError).message)
            },
        })
    }, [])

    const signOut = useCallback(() => {
        userPool.getCurrentUser()?.signOut()
        setAuthStatus(AuthStatus.SignedOut)
        setSession(null)
        setUser(null)
    }, [])

    useEffect(() => {
        getSession()
    }, [getSession])

    if (authStatus === AuthStatus.Loading) {
        return <p>Loading...</p>
    }

    return (
        <AuthContext.Provider
            value={{
                authStatus,
                session,
                user,
                signUp,
                signIn,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
