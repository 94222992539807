import { faChevronDown, faChevronUp, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../hooks'

export function Navbar() {
    const { signOut, user } = useAuthContext()

    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = useCallback(() => {
        setMenuOpen((prev) => !prev)
    }, [])

    return (
        <nav className="bg-stone-900 text-white shadow px-8 py-3 flex items-center justify-between">
            <h1 className="text-2xl font-semibold">
                <Link to="/">Struktur Sarr</Link>
            </h1>
            <button className="flex items-center" onClick={toggleMenu}>
                <span>
                    {user?.givenName} {user?.familyName}
                </span>
                <FontAwesomeIcon icon={menuOpen ? faChevronUp : faChevronDown} className="ml-2" />
            </button>

            {menuOpen && (
                <>
                    <div className="fixed top-14 right-6 bg-stone-700 p-4 z-50 rounded shadow">
                        <button className="flex items-center" onClick={signOut}>
                            Abmelden <FontAwesomeIcon icon={faSignOut} className="ml-2" />
                        </button>
                    </div>

                    <button className="fixed inset-0 z-40 cursor-default" onClick={toggleMenu}></button>
                </>
            )}
        </nav>
    )
}
