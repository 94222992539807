import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useNavigate } from 'react-router-dom'
import { Course } from '../../content'

export function CourseCard({ course }: { course: Course }) {
    const navigate = useNavigate()

    return (
        <div
            className="col-span-4 lg:col-span-2 2xl:col-span-1 w-full shadow cursor-pointer rounded hover:shadow-xl transition"
            onClick={() => navigate(`/kurse/${course.id}`)}
        >
            <div className="object-center object-cover w-full h-44 md:h-60 overflow-hidden">
                <LazyLoadImage
                    effect="blur"
                    placeholderSrc={'/assets/VERKAUFSFÖRDERUNG.png'}
                    src={course.image}
                    className="w-full rounded-t"
                    alt=""
                />
            </div>

            <div className="p-4">
                <h1 className="font-semibold text-2xl md:text-3xl truncate">{course.title}</h1>
                <p className="text-base text-gray-700 mt-2 mb-2">{course.author}</p>
            </div>
        </div>
    )
}
