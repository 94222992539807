import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Course } from '../../content'

export function CourseContent({ course }: { course: Course }) {
    return (
        <div className="flex-r justify-between">
            {course.content.map((content, index) => {
                return (
                    <NavLink
                        key={content.title}
                        to={`/kurse/${course.id}/${content.id}`}
                        className={({ isActive }) =>
                            `block ${
                                isActive ? 'bg-red-500 hover:bg-red-500 text-white' : 'hover:bg-gray-200'
                            } rounded transition duration-500 hover:shadow`
                        }
                        end
                    >
                        <div className="mt-4 px-4 py-2  ease-in-out cursor-pointer ">
                            <h1 className="text-xl font-medium">
                                <span className="pr-2 rounded">{index + 1}.</span> {content.title}
                            </h1>
                            <p className="mt-2">{content.description.slice(0, 200) + '...'}</p>
                        </div>
                    </NavLink>
                )
            })}
            {course.files && (
                <div>
                    <h3 className="text-2xl font-semibold mt-32 px-3 pb-2">Dateien:</h3>
                    {course.files.map((file) => {
                        return (
                            <a
                                key={file.title}
                                href={file.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-4 py-1 transition hover:opacity-75 text-lg flex items-center"
                            >
                                <FontAwesomeIcon icon={faDownload} />
                                &nbsp;&nbsp;&nbsp;{file.title}
                            </a>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
