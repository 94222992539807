export interface Content {
    id: string
    title: string
    link: string
    description: string
}

export interface File {
    id: string
    title: string
    link: string
}

export interface Course {
    id: string
    author: string
    content: Content[]
    files?: File[]
    description: string
    title: string
    image: string
}

export const content = [
    {
        id: 'verkaufsfoerderung',
        author: 'Jibril Sarr',
        content: [
            {
                id: 'wilkommen',
                title: 'WILKOMMEN',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/VERKAUFSFO%CC%88RDERUNG/videos/1.-WILLKOMMEN.mp4',
                description: 'In diesem Willkommensvideo hört ihr alles, was euch in diesem Kurs erwartet.',
            },
            {
                id: 'vorbereitung',
                title: 'VORBEREITUNG',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/VERKAUFSFO%CC%88RDERUNG/videos/2.-VORBEREITUNG.mp4',
                description: 'In diesem Video wird erläutert, wie wir uns auf das Verkaufsgespräch vorbereiten.',
            },
            {
                id: 'unternehmen',
                title: 'UNTERNEHMEN',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/VERKAUFSFO%CC%88RDERUNG/videos/3.-UNTERNEHMEN.mp4',
                description: 'In diesem Video wird erläutert, wie wir das Unternehmen beim Kunden vorstellen.',
            },
            {
                id: 'lebenslinie',
                title: 'LEBENSLINIE',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/VERKAUFSFO%CC%88RDERUNG/videos/4.+LEBENSLINIE.mp4',
                description: 'In diesem Video wird erläutert, wie wir die Lebenslinie vorstellen.',
            },
            {
                id: 'abschluss',
                title: 'ABSCHLUSS',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/VERKAUFSFO%CC%88RDERUNG/videos/5.+ABSCHLUSS.mp4',
                description: 'Hier wird der Abschluss beim Kunden simuliert.',
            },
        ],
        files: [
            {
                id: 'verkaufsunterlagen',
                title: 'Verkaufsunterlagen',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/VERKAUFSFO%CC%88RDERUNG/VERKAUFSUNTERLAGEN.pdf',
            },
            {
                id: 'datenaufnahmeblatt',
                title: 'Datenaufnahmeblatt',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/VERKAUFSFO%CC%88RDERUNG/Vertragsaufnahme.pdf',
            },
            {
                id: 'lebenslinie',
                title: 'Lebenslinie / Flexibilität / Kosten',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/VERKAUFSFO%CC%88RDERUNG/verkaufsgespraech-schriftlich.pdf',
            },
            {
                id: 'leitfaden',
                title: 'Leitfaden',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/VERKAUFSFO%CC%88RDERUNG/leitfaden.pdf',
            },
        ],
        description:
            'Bei diesen Verkaufsförderungs-Kurs lernt ihr alle Phasen des Verkaufs und das Verkaufsgespräch, wie es beim Kunden angewendet wird. Sie erlernen die Grundlagen des Verkaufsgesprächs, wie z.B. die Vorbereitung, das Unternehmen, die Lebenslinie, das Abschlussgespräch und die Verkaufsförderung.',

        image: 'https://content-sarr.s3.eu-central-1.amazonaws.com/VERKAUFSFO%CC%88RDERUNG/thumbnail.png',
        title: 'VERKAUFSFÖRDERUNG',
    },
    {
        id: 'rekrutierungsgespraech',
        author: 'Jibril Sarr',
        content: [
            {
                id: 'vorstellung',
                title: 'VORSTELLUNG',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/rekrutierung/videos/1-vorstellung.mp4',
                description: 'Hier wird der Kursinhalt vorgestellt.',
            },
            {
                id: 'smalltalk',
                title: 'SMALLTALK',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/rekrutierung/videos/2-smalltalk-lebenslauf.mp4',
                description: 'In diesem Abschnitt wird alles rund um das Thema Smalltalk vorgestellt.',
            },
            {
                id: 'rekrutierungsgespraech',
                title: 'REKRUTIERUNGSGESPRÄCH',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/rekrutierung/videos/3.mp4',
                description: 'In diesem Video wird euch die ERGO, Lebenslinie und das Prinzip der Privaten Altersvorsorge vorgestellt.',
            },
            {
                id: 'verguetung',
                title: 'Vergütung',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/rekrutierung/videos/4-gehaltsklassen.mp4',
                description: 'Hier wird das Vergütungssystem vorsgestellt.',
            },
            {
                id: 'gehaltsklassen',
                title: 'GEHALTSKLASSEN',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/rekrutierung/videos/4-gehaltsklassen.mp4',
                description: 'In diesem Video wird euch der Weg in die Stufe 2 erklärt.',
            },
            {
                id: 'strukturaufbau-leitungsverguetung',
                title: 'STRUKTURAUFBAU & LEITUNGSVERGÜTUNG',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/rekrutierung/videos/5-management-und-betriebssystem.mp4',
                description: 'In diesem Video wird euch die Strukturaufbau und die Leitungsvergütung vorgestellt.',
            },
            {
                id: 'persoenlicher-beweggrund',
                title: 'PERSÖNLICHER BEWEGGRUND',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/rekrutierung/videos/6-anerkennung.mp4',
                description: 'In diesem Video wird auf den persönlichen Beweggrund des Bewerbers eingegangen - Geld, Anerkennung, Weiterbildung',
            },
            {
                id: 'grundseminar-anmeldung',
                title: 'GRUNDSEMINAR ANMLEDUNG',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/rekrutierung/videos/7-grundseminar.mp4',
                description:
                    'In diesem Video wird der Ablauf des Seminares erklärt, hier bei ist es wichtig, dass der Bewerber die Seminarpauschale direkt nach dem Rekrutierungsgespräch bezahlt',
            },
        ],
        description: 'In diesem Kurs lernt ihr, das Rekrutierungsgespräch, aufgeteilt in den einzelnen Abschnitten.',
        image: 'https://content-sarr.s3.eu-central-1.amazonaws.com/rekrutierung/rekrutierungsgespraech.jpg',
        title: 'REKRUTIERUNGSGESPRÄCH',
    },
    {
        id: 'empfehlungsgeschaeft',
        author: 'Jibril Sarr',
        content: [
            {
                id: 'empfehlungsgeschaeft',
                title: 'EMPFEHLUNGSGESCHÄFT',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/rekrutierung/videos/8-empfehlungsgeschaeft.mp4',
                description: 'In diesem Video wird erklärt, beim Kunden Empfehlungen zieht.',
            },
        ],
        description:
            'Das Empfehlungsgeschäft ist das A&O, um kontinuierlich neue Kunden zu beziehen. In diesem Kurs lernt ihr es zu perfektionieren.',

        image: 'https://content-sarr.s3.eu-central-1.amazonaws.com/empfehlungsgeschaeft/empfehlungsgeschaeft.jpg',
        title: 'EMPFEHLUNGSGESCHÄFT',
    },
    {
        id: 'vertragseingabe',
        author: 'Jibril Sarr',
        content: [
            {
                id: 'vertragseingabe',
                title: 'VERTRAGSEINGABE',
                link: 'https://content-sarr.s3.eu-central-1.amazonaws.com/vertragseingabe/vertragseingabe.mp4',
                description: `Kunde anlegen: Kunden-> Interesenten anlegen -> speichern & zum Interessenten
Fehler Meldung: Adresse Anklicken und übernehmen
Kunde legitimieren: Rechts auf Neu->Dokument erfassen-> Dokumentart (Legitimationsdokument)
Dokument: Personalausweis/Aufenthaltstitel (Vorne+hinten) bei Dokument nur Perso oder Reisepass—-> Speichern & zurück
______________

Tarifrechner—> Zukunft—> Bei Erwachsenenwelt privater Rente das erste anklicken (1)
AuG Fragen:
2x Trifft zu —> Trifft nicht zu—> das erste(kann auch nachhaltig) —> kleine Beträge bis 250€/ mehrere Verträge oder größere Sparsumme bis 500€ —> 0€—> Nein—>Beruf eingeben—-> Berufliche Stellung (Angestellter)—->Kenntnisse wurden im Gespräch vermittelt—> 6x Keine Kenntnisse—> rest 0–>
dann speichern 
______________

Ergo Rente Ballance —> versicherungsbeginn—> Beitrag eingeben—> Rentegarantiezeit in Jahren = 20 Jahre —> klassische Anlage/Anlage in Fonds 0%/100%

Fonds löschen—> Fond hinzufügen—> weitere Fonds i sharecore s&p 500 (50%) / i sharecore MSCI Wolrd UCITS (50%)

Automatische Anpassung —> nicht gewünscht—
Berufsunfähigkeit —> nicht gewünscht 
—> Berechnen
—> weiter mit —> Versicherungsvorschlag Schnelldruck —> weiter mit 
—> Anträge bearbeiten—> Telefon/ Email Ja,Ja, Heckchen
—> Lastschriftbeginn
—> + Bankbezeichnung & IBAN 
—> weiter

—> Verfahren des Vertragsschluss —> invitatio Anfrage oder Antrag —> Ausweisdokument ausfüllen: Personalausweis ( Bei Reisepass: Reisepass anklicken)
—> Vorraussetzung für QR-Code: Ja 
—> Vertretungsverhältnis: Nein—> Nein—> Ja
—> weiter

Versandweg an Geschäftsstelle 
—> weiterer Provisionsempfänger—> Bsp 886438750 Anteil 99%
Geschäftsstelle 886438750
—> weiter mit —> Beratungsdokumentation bearbeiten 
______________

Beratungsdokumentation bearbeiten
—> Empfehlungen und Vorschläge: Siehe Notizen!! (1.2.3 ausfüllen 4. so lassen 5. ausfüllen)
—> speichern&zurück 

—> weiter mit —> Antragsdokumente&eSigna 
—>Anträge digital signieren —> Antragsdokumente signieren mit Tablet/QR-Code

—> Status aktualisieren—> zurück —> zurück 

Wenn „Antrag elektronisch unterschrieben“
—> weiter mit  —> Anträge Senden

Wenn „Antrag teilweise elektronisch unterschrieben“ (Anträge nicht senden!) 
—> speichern & Beratung verlassen`,
            },
        ],
        description: 'Video zur Antragseingabe Ergo Rente Balance.',
        image: 'https://content-sarr.s3.eu-central-1.amazonaws.com/vertragseingabe/vertragseingabe.png',
        title: 'VERTRAGSEINGABE',
    },
]
