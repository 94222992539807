import { Navigate } from 'react-router-dom'
import { AuthStatus } from '../contexts'
import { useAuthContext } from '../hooks'

interface AuthIsSignedOutProps {
    children: React.ReactNode
}

export const AuthIsSignedOut = ({ children }: AuthIsSignedOutProps) => {
    const { authStatus } = useAuthContext()

    return <>{authStatus === AuthStatus.SignedOut ? children : <Navigate to="/" />}</>
}
