import { Navbar, CourseCard } from '..'
import { content } from '../../content'

export function ListOfCourses() {
    return (
        <>
            <Navbar />
            <div className="grid grid-cols-4 gap-8 p-8">
                {content.map((course) => {
                    return <CourseCard course={course} key={course.id} />
                })}
            </div>
        </>
    )
}
