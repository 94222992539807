import { Navigate } from 'react-router-dom'
import { AuthStatus } from '../contexts'
import { useAuthContext } from '../hooks'

interface AuthIsSignedInProps {
    children: React.ReactNode
}

export const AuthIsSignedIn = ({ children }: AuthIsSignedInProps) => {
    const { authStatus } = useAuthContext()

    return <>{authStatus === AuthStatus.SignedIn ? children : <Navigate to="/sign-in" />}</>
}
