import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'

export function SignUp() {
    const { signUp } = useAuthContext()

    const [givenName, setGivenName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [level, setLevel] = useState('1')

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            signUp(givenName, familyName, email, password, level)
        },
        [email, password, signUp]
    )

    const handleGivenNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setGivenName(e.target.value)
    }, [])

    const handleFamilyNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setFamilyName(e.target.value)
    }, [])

    const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }, [])

    const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }, [])

    const handleLevelChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        setLevel(e.target.value)
    }, [])

    return (
        <div className="bg-gray-50 pt-60 h-screen">
            <form className="max-w-md w-full mx-auto shadow p-6 bg-white" onSubmit={handleSubmit}>
                <h1 className="text-3xl font-semibold text-center">
                    <span className="text-gray-800">Struktur Sarr</span>
                </h1>
                <div className="mt-4">
                    <label className="text-gray-600" htmlFor="givenNamw">
                        Vorname
                    </label>
                    <input
                        id="givenName"
                        type="text"
                        className="w-full p-2 shadow bg-gray-100"
                        value={givenName}
                        onChange={handleGivenNameChange}
                        required
                    />
                </div>
                <div className="mt-4">
                    <label className="text-gray-600" htmlFor="familyName">
                        Nachname
                    </label>
                    <input
                        id="familyName"
                        type="text"
                        className="w-full p-2 shadow bg-gray-100"
                        value={familyName}
                        onChange={handleFamilyNameChange}
                        required
                    />
                </div>
                <div className="mt-4">
                    <label className="text-gray-600" htmlFor="email">
                        E-Mail
                    </label>
                    <input id="email" type="email" className="w-full p-2 shadow bg-gray-100" value={email} onChange={handleEmailChange} required />
                </div>
                <div className="mt-4">
                    <label className="text-gray-600" htmlFor="password">
                        Passwort
                    </label>
                    <input
                        id="password"
                        type="password"
                        className="w-full p-2 shadow bg-gray-100"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    />
                </div>
                <div className="mt-4">
                    <label className="text-gray-600" htmlFor="level">
                        Stufe
                    </label>
                    <select id="level" value={level} onChange={handleLevelChange} className="w-full p-2 shadow bg-gray-100" required>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </select>
                </div>
                <div className="mt-6">
                    <button type="submit" className="w-full p-2 bg-red-500 shadow text-white font-semibold transition hover:opacity-75">
                        Registrieren
                    </button>
                </div>
            </form>
            <div className="text-center mt-4">
                <Link to="/sign-in" className="text-gray-600 py-1">
                    Bereits einen Account erstellt?
                </Link>
            </div>
        </div>
    )
}
